import * as React from "react"
import doggo from "../assets/7jOyeK5.gif"
import "../css/styles.css";

// markup
const IndexPage = () => {
  return (
    <html lang="en">
    <head>
      <meta charset="utf-8" />
    
      <title>Ricefishie</title>
      <meta name="description" content="Ricefishie's personal site" />
      <meta name="author" content="Ricefishie" />    
    </head>
    
    <body>
        <img src={doggo} class="center"/>
    </body>
    </html>
  )
}

export default IndexPage
